.chart_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.merged{
height: 400px !important;
}

.echarts-tooltip {
    z-index: 9999 !important; 
    white-space: normal !important; 
    overflow: visible !important; 
    max-width: 300px; 
    word-break: break-word; 
   
}