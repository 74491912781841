@import '../../../styles/global-settings.scss';

.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndnode.circle {
    border: 2px solid #ff0072;
    border-radius: 50%;
    width: 50px;
    height: 35px;
    text-align: center;
    background-color: #ffe6f1;
    cursor: pointer;
}
.dndnode.start {
    width: 50px;
    height: 35px;
    background-color: #a6eb52;
    border-radius: 50%;
    border: 2px solid #4c8404;
    @include flexprob;
    color: white;
    font-size: 18px;
}

.dndnode.end {
    width: 50px;
    height: 35px;
    background-color: #f35c5c;
    border-radius: 50%;
    border: 2px solid #e80909;
    @include flexprob;
    color: white;
    font-size: 18px;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    // height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 250px;
    }
}

.attributeFormWrapper__wrapper.workflow {
    width: 50% !important;
    padding: 10% 0px !important;
}
.createAttributeWrapper__headerWrapper.workflow {
    border-bottom: 1px solid $neutral-5;
    padding: 0px;
    width: 100%;
}
.createAttributeWrapper__heading.workflow {
    width: 100%;
}
.createAttributeWrapper__versionDropdown.workflow {
    margin-left: auto;
    display: flex;
    gap: 10px;
    .ant-select {
        width: 160px;
    }
    .customButton {
        width: 80px;
    }
}
.versionDropdown {
    width: 220px !important;
}
.metaData {
    display: flex;
    gap: 20px;
}
.createAttributeWrapper__scrollContent.workflow {
    height: calc(100vh - 300px);
    overflow-y: scroll;
}
.createAttributeWrapper__scrollContentWithoutFooter {
    height: calc(100vh - 220px);
    overflow-y: scroll;
}
.createAttributeWrapper__card.workflow {
    height: calc(100vh - 80px);
    overflow-y: hidden;
    @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('borderDarkColor') !important;   
    }
}


.customNodes {
    @include flexprob;
    flex-wrap: wrap;
    gap: 10px;
    align-content: center;
    max-width: 76%;
    margin: 0 auto;
}

.dndnode.decision {
    width: 35px;
    height: 35px;
    background-color: #2196f3; /* Light pink */
    transform: rotate(45deg);
    position: relative;
    border: 2px solid #045ea8;
    @include flexprob;
    left: 6px;
}
.dndnode.event {
    width: 50px;
    height: 35px;
    background-color: #131b2e;
    border-radius: 10px;
}
.dndnode.timeCapsuleEvent {
    width: 50px;
    height: 35px;
    background-color: #ead233;
    border-radius: 10px;
}
.dndnode.tableEvent {
    width: 50px;
    height: 35px;
    background-color: #576de7;
    border-radius: 10px;
}
.dndnode.tagEvent {
    width: 50px;
    height: 35px;
    background-color: #57d6e7;
    border-radius: 10px;
}
.dndnode.alertEvent {
    width: 50px;
    height: 35px;
    background-color: #e96ef5;
    border-radius: 10px;
}
.dndnode.kpiEvent {
    width: 50px;
    height: 35px;
    background-color: #e78c57;
    border-radius: 10px;
}

.dndnode.action {
    width: 50px;
    height: 35px;
    background-color: #27437a;
    border-radius: 7px;
}
.nodeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.modalContainer {
    .ant-form-item {
        margin: 8px;
        height: 80px;
        width: 310px;
    }
    .ant-form-item-control-input {
        width: 100% !important;
    }
    .ant-form {
        max-height: calc(100vh - 180px);
        overflow: auto;
    }
    overflow-y: auto;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 52%;
    padding-left: 35%;
    position: absolute;
    bottom: 20px;
}

.workflowProgresssBar {
    position: absolute;
    right: 20px;
    width: 26%;
}

.subjectInput {
    margin: 0px !important;
    width: 160px !important;
    height: 50px !important;
    margin-top: -2px !important;
}

.bodyRowOne {
    margin-top: 10px;
    color: $gray-2;
}

.bodyRowTwo {
    color: $gray-2
}

.selectAllCheckbox {
    max-width: 310px;
}

.nameInput {
    margin-bottom: 8px;
    width: 400px;
}

.descInput {
    margin-bottom: 8px;
    color: $gray-2;
}

.delayComponent {
    display: flex;
    gap: 2px;
}

.mapConditionComponent {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 8px;
    .ant-card-body {
        padding: 12px;
    }
}

.tableFormItem {
    height: 20rem !important;
}

.tableValColumn {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 4px;
}
.ant-select-selection-item p {
    display: none;
  }

.ant-select-disabled .ant-select-selection-item span {
    color: rgb(157, 156, 156) !important;
}