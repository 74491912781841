
@import '../../../../styles/global-settings.scss';

.regression-details-modal {
    width: 650px!important;
    .ant-modal-title {
        border-bottom: 1px solid #dedede;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .ant-modal-content {
        padding: 0;
    }
    .ant-modal-body {
        padding: 0 20px 20px;
    }
    .ant-modal-footer {
       
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-btn-default {
        padding: 15px 41px;
        margin-bottom: 20px;
    }
    .ant-table-content{
        ::-webkit-scrollbar {
            width: 10px;
            height: 4px!important;
        }
        
    }
    
}
