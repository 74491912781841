@import '../../../../styles/global-settings.scss';


.chart-properties-scatter-drawer{
    .correlation-header{
       display: flex;
       flex-direction: column;
       gap: 20px;
    }
   
        .correlation-option {
            display: flex;  
            margin-bottom: 10px;
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap:5px;
          
        }
        .ant-drawer-header-title {
            flex-direction: row-reverse;
        }
    
    
   
}