@import '../../../../styles/global-settings.scss';
.tagTable{
    .ant-spin-container {
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .ant-empty-normal{
        height: calc(100vh - 800px)!important;
    }
}
