@import '../../../../styles/global-settings.scss';
@import '../../../../styles/colorVariable';

.viewTable-template {
    padding: 10px;
    .ant-card-body{
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important; 
            border-radius: 7px;  
        }
    }
    .wrapper {
        padding-top: 5px;
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        .anticon svg {
            svg {
                @include themify($themes) {
                    color: themed('svgColor') !important;
                    stroke: themed('svgStrokeColor') !important;
                }      
            }
            path {
                @include themify($themes) {
                    fill: themed('svgColor') !important;
                }
            } 
        }
    }

    .header {
        .anticon-arrow-left {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        align-items: center;
        width: 205px;
        height: 24px;
        }
    }
    .name-description {
        .ant-form {
            display: flex;
        }
        .name {
            margin-right: 8px;
            width: 50%;
        }
        .description {
            margin-right: 8px;
            width: 50%;
        }
        .type {
            width: 30%;
        }
        formIds{
            width: 30%;
            margin-right: 8px;

        }
    }
    .form {
        .EditTableCreateTable {
            &__Wrapper {
                &__button {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
                &__Table {
                    height: 440px;
                }
                &__Footer {
                    @include flexprob;
                    background: $neutral-1;
                    width: 100%;
                    padding: 12px 24px 16px 24px;
                    z-index: 1;
                    gap: 8px;
                    border-top: 0.5px solid $border;
                }
                &__FooterWrapper {
                    width: 110px;
                }
            }

            .deleteicon {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .editable-cell {
                position: relative;
            }

            .editable-cell-value-wrap {
                padding: 5px 12px;
                cursor: pointer;
            }

            .editable-row:hover .editable-cell-value-wrap {
                padding: 4px 11px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
            }
        }
    }
}

.ant-table-thead > tr > th.columnName,
.ant-table-thead > tr > th.dataType,
.ant-table-thead > tr > th.uom {
    padding-left: 25px; // Adjust the padding to move the header to the right
}
.icon-pair-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

.icon-label {
        @include themify($themes) {
            color: themed('headingColor') !important;
        } 
    }
.icon-content{
    svg {
        @include themify($themes) {
            color: themed('tableSvgColor') !important;
            stroke: themed('svgTableStrokeColor') !important;
        }
    }
    path {
        @include themify($themes) {
            fill: themed('tableSvgColor') !important;
        }
    }
    }
}

@mixin icon-container-style($border-color: #ccc, $cursor: pointer, $opacity: 1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    padding: 16px;
    border: 2px solid $border-color;
    border-radius: 8px;
    cursor: $cursor;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
    opacity: $opacity;
}

.icon-container {
    @include icon-container-style();

    &.selected {
        border-color: blue;
    }

    &.disabled {
        @include icon-container-style($border-color: #ccc, $cursor: not-allowed, $opacity: 0.5);
    }

    .icon-content {
        margin-bottom: 8px;

        svg {
            width: 50px;
            height: 50px;
        }
    }

    .icon-label {
        font-size: 14px;
        color: #333;
        text-align: center;
    }
}
