@import '../../../styles/global-settings.scss';

.collapse-container {
    display: flex;
    .switch-module-icon {
        width: 80%;
    }
}

.expand-container {
    display: flex;
    flex-direction: column;
}

.switch-module-icon {
    display: flex;
    cursor: pointer;
    color: $primary-2;
    padding: 16px 4px;
    justify-content: center;
}
// will use later
// .theme-dark .switch-module-icon img {
//     width: 27px;
//     height: 25px;
// }
.filter-navigator {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 14px;
    padding: 35px 18px 15px 0px;
    height: 48px;
    cursor: pointer;
    .anticon svg {
        width: 16px;
        height: 16px;
    }
    .ant-input-affix-wrapper {
        color: rgba(0, 0, 0, 0.3);
        border: none;
        width: 209px;
        height: 40px;
        background: rgba(255, 255, 255, 0.75);
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            border-color: themed('borderSearch') !important;
            border: 1px solid themed('borderSearch');
        }
        input {
            @include themify($themes) {
                background: themed('bodyCardBackgroundColor') !important;
            }
        }
        input::placeholder {
            @include themify($themes) {
                color: themed('textColor') !important;
            }
        }
    }
}

.ant-menu-light {
    background-color: #f1f3fc;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor');
    }
}

.ant-menu-submenu.ant-menu-submenu-popup {
    ul.ant-menu.ant-menu-sub {
        max-height: calc(100vh - 300px);
        .ant-menu-item.ant-menu-item-active:hover {
            background-color: #f1f3fc !important;
            @include themify($themes) {
                background: themed('hoverMenuColor') !important;
                color: themed('headingColor');
            }
            svg,
            a,
            span {
                @include textfontstyle;
                color: black;
                @include themify($themes) {
                    color: themed('textColor');
                }
            }
        }

        .ant-menu-submenu-active.ant-menu-submenu-open,
        .ant-menu-submenu-title:hover {
            background-color: #f1f3fc !important;
            @include themify($themes) {
                background: themed('hoverMenuColor') !important;
                color: themed('textColor');
            }
            svg,
            a,
            span {
                color: black;
                @include themify($themes) {
                    color: themed('textColor');
                }
            }
        }

        .ant-menu-item.ant-menu-item-selected {
            background-color: $primary-4 !important;
            @include themify($themes) {
                background: themed('hoverMenuColor') !important;
                color: themed('textColor');
            }
            a,
            span {
                color: white;
                stroke: white;
            }
        }

        .ant-menu-submenu-title {
            overflow: visible !important;
            svg,
            a,
            span {
                color: black;
                @include themify($themes) {
                    color: themed('headingColor');
                }
            }
        }
    }
}

.ant-menu-title-content {
    a {
        color: black !important;
        text-decoration: none !important;
        @include themify($themes) {
            color: themed('headingColor') !important;
            text-decoration: none !important;
        }
    }
}

.sider-container-collapsed {
    .ant-menu-submenu-title span,
    .ant-menu-submenu-title a {
        pointer-events: none;
        @include themify($themes) {
            color: themed('headingColor');
        }
    }
    .ant-menu-item {
        margin-inline: 0px !important;
        margin-block: 0px !important;
    }
    ul.ant-menu-inline-collapsed {
        .ant-menu-item-selected {
            background-color: $primary-4 !important;
            @include themify($themes) {
                background: themed('hoverMenuColor') !important;
            }
            display: flex;
            align-items: center;
            a,
            span {
                color: white;
                stroke: white;
                stroke-width: 0.8px;
            }
        }

        .ant-menu-submenu-selected > .ant-menu-submenu-title {
            background-color: $primary-4 !important;
            @include themify($themes) {
                background: themed('hoverMenuColor') !important;
            }
            display: flex;
            align-items: center;
            a,
            span {
                color: white;
                stroke: white;
                stroke-width: 0.8px;
            }
        }

        .ant-menu-submenu-open.ant-menu-submenu-active:hover {
            .ant-menu-submenu-title:hover {
                background-color: #dde2f9 !important;
                @include themify($themes) {
                    background: themed('hoverMenuColor') !important;
                }
                display: flex;
                align-items: center;
            }
        }

        .ant-menu-submenu-open.ant-menu-submenu-active
            .ant-menu-item-selected.ant-tooltip-open:hover {
            .ant-menu-submenu-title:hover {
                background-color: $primary-4 !important;
                @include themify($themes) {
                    background: themed('hoverMenuColor') !important;
                }
                display: flex;
                align-items: center;
            }
        }

        .ant-menu-item-active {
            background-color: #dde2f9 !important;
            @include themify($themes) {
                background: themed('hoverMenuColor') !important;
            }
            display: flex;
            align-items: center;
        }
    }
}

.side-menu-tabs-expanded {
    .ant-menu-vertical {
        border-inline-end: 0 !important;
    }
    margin: 16px;
    .ant-menu-item {
        margin-inline: 0px !important;
        margin-block: 0px !important;
    }
    .ant-menu-submenu {
        padding-bottom: 0;
    }

    li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
        background-color: $primary-4 !important;
        @include themify($themes) {
            background: themed('hoverMenuColor') !important;
        }

        a,
        span {
            color: white;
            stroke: white;
            stroke-width: 0.8px;
        }
    }

    .ant-menu-item.ant-menu-item-selected {
        background-color: $primary-4 !important;
        a,
        span {
            color: white;
            stroke: white;
            stroke-width: 0.8px;
        }
    }

    .ant-menu-item.ant-menu-item-active,
    .ant-menu-submenu-open.ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        background-color: #dde2f9 !important;
        width: 100% !important;
        margin-inline: 0 !important;
        @include themify($themes) {
            background: themed('hoverMenuColor') !important;
        }
        svg,
        a,
        span {
            color: black;
            @include themify($themes) {
                color: themed('headingColor');
            }
        }
        .ant-menu-title-content {
            a {
                color: black !important;
            }
        }
    }
}
.collapsed {
    ul.ant-pagination {
        left: 20px;
    }
}
.ant-menu-submenu-title {
    margin-inline: 0 !important;
}
