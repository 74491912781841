@import '../../../styles/global-settings.scss';

.customPagination {
    ul.ant-pagination {
        position: fixed;
        bottom: 0;
        left: 0;
        background: $neutral-1;
        box-shadow: 0px -11px 24px rgba(0, 0, 0, 0.13);
        width: 100%;
        padding: 12px 24px 16px 24px;
        z-index: 1;
        text-align: end;
        display: flex;
        justify-content: flex-end;
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
            color: themed('textColor') !important;
        }
        .ant-pagination-item a {
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
           
        }
    }
    .ant-pagination-item {
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border-color: themed('lightWhiteColor') !important;
            border: 1px solid themed('lightWhiteColor') !important;
        }
    }
    .ant-select-selector {
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;
            border-color: themed('borderDarkColor') !important;
        }
    }
    .ant-select-open .ant-select-selection-item {
        @include themify($themes) {
            color: themed('headingColor') !important;
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        @include themify($themes) {
            background: themed('hoverMenuColor') !important;
            color: themed('textColor') !important;
        }
    }
    .anticon svg {
        @include themify($themes) {
            color: themed('LightSvgColor') !important;
        }
    }
    .ant-pagination .ant-pagination-item-active {
        @include themify($themes) {
            border-color: themed('brandColor') !important;
        }
        
    }
   
}

.customPaginationStatic {
    ul.ant-pagination {
        position: static !important;
        bottom: 0;
        left: 0;
        right: 0;
        background: $neutral-1;
        width: 100%;
        padding: 12px 24px 16px 24px;
        padding-left: 18%;
        z-index: 1;
        text-align: end;
        box-shadow: 0px -11px 24px rgba(0, 0, 0, 0.13);
    }
}
