@import '../../../styles/colorVariable';
@import '../../../styles/global-settings.scss';
.text-updater-node {
    height: 54px;
    border: 1px solid $neutral-8;
    padding: 5px;
    border-radius: 5px;
    background: $primary-10;
    border-color: $primary-3;
    width: 222px;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
 }
    .react-flow__handle {
        background-color: $primary-4 !important;
    }
}
.text-action-node {
    height: 54px;
    border: 1px solid $neutral-8;
    padding: 5px;
    border-radius: 5px;
    background: #dcf1de;
    border-color: #2e8128;
    width: 222px;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
 }
    .react-flow__handle {
        background-color: $primary-4 !important;
    }
}
.text-decision-node {
    height: 95px;
    border: 1px solid $neutral-8;
    padding: 5px;
    border-radius: 5px;
    background: #f3f2b7;
    border-color: #bea70a;
    width: 95px;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
 }
    .react-flow__handle {
        background-color: $primary-4 !important;
    }
    transform: skew(180deg, 180deg) rotate(45deg);
}
.parentNode {
    height: 54px;
    border: 1px solid $neutral-8;
    padding: 5px;
    border-radius: 5px;
    background: $reset-background;
    border-color: $progress-border;
    width: 222px;
    @include themify($themes) {
        background: themed('bodyBackgroundColor') !important;
        color: themed('headingColor') !important;
 }
}
.text-updater-node label {
    display: block;
    color: $gray-9;
    font-size: 12px;
}
.decisionLabel {
    text-align: center;
    display: flex;
    font-size: 12px;
    justify-content: flex-start;
    transform: skew(180deg, 180deg) rotate(-45deg);
    line-height: 20px;
    word-break: break-all;
    width: 70px;
    margin-top: 16px
}

.nodeDesign {
    display: flex;
    flex-direction: row;
}
.icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    cursor: default;
    .iconsSpace {
        width: 22px;
    }
}
.icon{
    cursor: pointer;
}
.label {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 36px;
}

.react-flow__handle-top.nodrag.nopan.topBottom.target {
    left: 47%;
}
.react-flow__handle-bottom.nodrag.nopan.topBottom.source {
    left: 47%;
}

.react-flow__handle-right.leftRight.source {
    right: 10%;
}
.react-flow__edge-textbg {
    fill: $gray-11;
}
.react-flow__viewport {
    transform: translate(0, 0) scale(1);
}
.react-flow__node-toolbar{
    position: absolute;
    top: 3%;
    left: -3%;
}

.iconOnDecision {
    position: absolute;
    top: 8%;
    left: -2%;
}