@import '../../../styles/global-settings.scss';

.ant-table-cell {
    padding: 5px !important;
    font-size: 12px;
}

.dataVisualizationDashboard {
    .card-heading {
        height: 116px;
    }
    .dataVisualizationWrapperScrolContent {
        overflow-y: scroll;
        height: calc(100vh - 180px);
    }

    .ant-card .ant-card-body {
        padding-right: 20px;
        padding-top: 16px;
    }
    .dataVisualizationWrapper {
        @include fontTextStyle;
    }
}

.data-visualization-container {
    background-color: $neutral-1;
    height: calc(100vh - 186px);
    margin: 8px;
    display: flex;
    .ant-table-wrapper.child {
        border: 2px solid green;
        overflow-y: auto !important;
        height: 100%;
    }
    .ant-table-wrapper {
        overflow-y: auto;
        &.trending-page {
            height: calc(100vh - 640px);
        }
        &.other-page {
            height: calc(100vh - 255px);
        }
    }
    .ant-table-selection {
        display: none !important;
    }
    .left {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 8px;
        border-right: 1px solid #dedede;
        .device-tag-selector-header {
            display: flex;
            place-content: space-between;
        }
        .child {
            padding: 6px;
            padding-top: 8px !important;
            .ant-select-selector {
                padding: 7px;
                height: 32px !important;
            }
            span.ant-select-arrow {
                top: 24px !important;
            }
            span.ant-select-clear {
                top: 24px !important;
            }
        }
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .leftCollaspe {
        width: 0%;
        display: none;
    }
    .ant-input-affix-wrapper.search-tag {
        padding: 0 !important;
    }
    .search-tag.tag {
        margin-top: 40px;
    }
    .search-tag {
        margin-top: 24px;
        margin-left: 4px;
        height: 10px;
        width: calc(100% - 7px);
        span {
            color: $gray-9;
            margin-right: 2px;
        }
    }
    .right {
        width: 75%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .rightFull {
        width: 100%;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
        .expandIcon {
            margin-right: 10px;
        }
    }
    &__header {
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
            color: themed('headingColor') !important;

            .ant-radio-group.ant-radio-group-outline label {
                @include themify($themes) {
                    background-color: themed('bodyBackgroundColor') !important;
                    color: themed('headingColor') !important;
                    border: 1px solid themed('borderDarkColor') !important;
                }
            }
            .ant-radio-button.ant-radio-button-checked {
                @include themify($themes) {
                    background-color: themed('brandColor') !important;
                    border: 1px solid themed('borderDarkColor') !important;
                    border-radius: 5px;
                }
            }

            .ant-radio-button-wrapper {
                &:hover {
                    @include themify($themes) {
                        background-color: themed(
                            'hoverBackgroundColor'
                        ) !important;
                        border-color: themed('hoverColor') !important;
                        color: themed('hoverColor') !important;
                    }
                }
            }
        }

        &__header-exportButton {
            button {
                .export-name {
                    color: $primary-5 !important;
                }
                border: 1px solid var(--neutral-5, #d9d9d9);
                background: transparent;

                span:nth-child(1) {
                    svg {
                        fill: $title-45;
                        color: $title-45;
                        stroke: none;
                    }
                }
                span:nth-child(2) {
                    color: $title-85;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            button:hover {
                border: 1px solid $primary-5;
                background-color: transparent !important;

                span:nth-child(1),
                .custom-download.as.csv {
                    svg {
                        color: $primary-5 !important;
                        stroke: none;
                    }
                    span {
                        color: $primary-5 !important;
                    }
                    path {
                        fill: $primary-5 !important;
                    }
                }
                span:nth-child(2) {
                    color: $primary-5 !important;
                }
            }
        }
        .custom-download.as.csv {
            svg {
                color: $title-85;
                stroke: none !important;
            }
            span {
                color: $title-85;
            }
            path {
                fill: $title-85;
            }
        }
    }
    &__multiAxisChart {
        g.highcharts-range-selector-buttons {
            display: none !important;
        }
        height: 400px;
        overflow-y: scroll;
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
        &__multiAxisChartTable {
            padding: 10px;
        }
        &__header-exportButton {
            button {
                .export-name {
                    color: $primary-5 !important;
                }
                border: 1px solid var(--neutral-5, #d9d9d9);
                background: transparent;

                span:nth-child(1) {
                    svg {
                        fill: $title-45;
                        color: $title-45;
                        stroke: none;
                    }
                }
                span:nth-child(2) {
                    color: $title-85;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            button:hover {
                border: 1px solid $primary-5;
                background-color: transparent !important;

                span:nth-child(1),
                .custom-download.as.csv {
                    svg {
                        color: $primary-5 !important;
                        stroke: none;
                    }
                    span {
                        color: $primary-5 !important;
                    }
                    path {
                        fill: $primary-5 !important;
                    }
                }
                span:nth-child(2) {
                    color: $primary-5 !important;
                }
            }
        }
        @include themify($themes) {
            background: themed('bodyBackgroundColor') !important;
        }
    }
    .table {
        margin-top: 8px;
        height: 29%;
    }
    .deviceTagsHeading {
        font-size: 16px;
        font-weight: 400;

        @include themify($themes) {
            color: themed('headingColor') !important;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .expandExportOption {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .export-fn {
            border: 0 !important;
        }
    }
    .icon-Inside svg {
        position: absolute;
        right: 37px;
        top: 5px;
        z-index: 999;
    }

    .icon-Inside {
        position: relative;
    }
    .sampling {
        .ant-select-selector {
            border: 0;
            background: #f8f9fa !important;
        }
        .ant-btn-default {
            border: 0;
            background: #f8f9fa !important;
        }
    }

    .sampling.functions {
        cursor: pointer;
    }
    .merge {
        display: flex;
        align-items: center;
    }
    .merge label {
        margin-right: 10px;
    }
    .expandFrequency {
        background: #ffffff;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.88);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
        @include themify($themes) {
            background: themed('bodyCardBackgroundColor') !important;
        }
    }

    .expandIcon {
        cursor: pointer;

        @include themify($themes) {
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 5px;
        }

        svg {
            @include themify($themes) {
                stroke: themed('svgColor') !important;
            }
        }

        rect {
            @include themify($themes) {
                fill: themed('bodyBackgroundColor') !important;
            }
        }

        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
                fill-opacity: 1;
            }
        }
    }

    .disableExpandBtn {
        margin-right: 12px;
        cursor: not-allowed;

        @include themify($themes) {
            border: 1px solid themed('borderDarkColor') !important;
            border-radius: 5px;
        }

        svg {
            @include themify($themes) {
                stroke: themed('svgColor') !important;
            }
        }

        rect {
            @include themify($themes) {
                fill: themed('bodyBackgroundColor') !important;
            }
        }

        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }

    .closeIcon {
        margin-right: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(248, 249, 250);
        padding: 8px;
        border-radius: 6px;
        @include themify($themes) {
            background-color: themed('bodyBackgroundColor') !important;
            border: 1px solid themed('borderDarkColor') !important;
        }
        svg {
            @include themify($themes) {
                color: themed('svgColor') !important;
                stroke: themed('svgColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('svgColor') !important;
            }
        }
    }
    .data-visualization-container.trending-container.hideTrendingArea {
        display: none;
    }

    // might use later

    // .data-visualization-container.trending-container{
    //     .right {
    //             @include themify($themes) {
    //                 background: themed('bodyBackgroundColor') !important;
    //             }
    //     }
    // }

    // .data-visualization-container.other-container {
    //     .right {
    //         @include themify($themes) {
    //             background: themed('bodyBackgroundColor') !important;
    //         }
    // }

    .selecedTagCloseIcon {
        svg {
            @include themify($themes) {
                color: themed('headingColor') !important;
            }
        }
        path {
            @include themify($themes) {
                fill: themed('headingColor') !important;
                stroke: themed('headingColor') !important;
            }
        }
    }
    .ant-spin-spinning {
        @include flexprob;
        height: 50vh;
    }
}

/* Set width of the dropdown overlay */
.custom-dropdown {
    width: 120px !important; /* Use !important to override inline styles */
}

.functionPopover label {
    padding: 7px;
    label.ant-checkbox-wrapper.ant-checkbox-group-item:hover {
        background-color: #e6f4ff;
    }
}

/* Set width of the dropdown overlay */
.custom-dropdown {
    width: 120px !important; /* Use !important to override inline styles */
}

.functionPopover label {
    padding: 7px;
    label.ant-checkbox-wrapper.ant-checkbox-group-item:hover {
        background-color: #e6f4ff;
    }
}

/* scroll  */
// .ant-table-body::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
// 	border-radius: 10px ;
// 	background-color: #F5F5F5;
// }

// .ant-table-body::-webkit-scrollbar
// {
// 	width: 12px;
// 	background-color: #F5F5F5;
// }
.tableScroll .ant-table-body {
    overflow-x: hidden;
    .dataTable__pagination {
        padding: 0;
        bottom: 30px;
    }
    .ant-table-body {
        overflow-x: hidden;
    }
}
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-chart {
    background: #fff;
    width: 90%;
    height: 90%;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 3%;
    span.anticon.anticon-close.close-fullscreen {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2%;
    }
}

.fullscreen-chart-container {
    height: 100%;
    width: 100%;
}

.tag_kpi {
    padding: 8px;
    height: 100%;
    &__header {
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        &__select {
            width: 100%;
        }
    }
    &__section {
        flex-grow: 1;
        display: grid;
        grid-template-rows: repeat(1fr, 1fr);
        gap: 5px;
        &__tag_kpi {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}

.kpi_tag_table {
    position: relative;
    height: 100%;
    &__section {
        position: relative;
        height: 100%;
        overflow-y: scroll;
    }
    &__float {
        position: absolute;
        width: 100%;
    }
}
.kpi_tag_column {
    &__title {
        display: flex;
        font-size: 12px;
        gap: 2px;
        justify-content: space-between;
        align-items: center;
        &__arrow {
            display: flex;
            flex-direction: column;
        }
    }
}
.ant-select.ant-tree-select.ant-select-outlined.child.tag_kpi__header__select {
    padding-top: 20px !important;

  
}

.ant-select.ant-tree-select.ant-select-outlined.child.tag_kpi__header__select.clear{
    span.ant-select-clear {
        top: 35px !important;
    }
    span.ant-select-arrow{
        top: 35px !important;
    }
}
 
